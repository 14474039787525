// import Swiper from './swiper';

let carousel = document.querySelector('.swiper-container');

if (carousel) {

  var swiperSteps = new Swiper('.carousel-steps', {
    slidesPerView: 1,
    spaceBetween: 32,
    loop: true,
    allowTouchMove: true,
    pagination: {
       el: ".swiper-pagination",
     },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  var swiperHistory = new Swiper('.carousel-history', {
    slidesPerView: 4,
    spaceBetween: 32,
    loop: false,
    allowTouchMove: true,
    pagination: {
       el: ".swiper-pagination",
     },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 3,

      },
      1024: {
        slidesPerView: 3,

      },
      1160: {
        slidesPerView: 4,
      },
    },
  });

}
