/*
Adds a class after scroll to engage opaque Styling

https://stackoverflow.com/questions/12558311/add-remove-class-with-jquery-based-on-vertical-scroll
*/

jQuery(document).ready(function() {

	setTimeout(function(){

	//caches a jQuery object containing the header element
	    var header = jQuery(".header");

			if (document.body.classList.contains('static-nav')) {
				header.addClass("opaque");
			} else {

		    jQuery(window).scroll(function() {
		        var scroll = jQuery(window).scrollTop();

		        if (scroll >= 72) {
		            header.addClass("opaque");
		        } else {
		            header.removeClass("opaque");
		        }
		    });

				jQuery(window).height(function() {
					var scroll = jQuery(window).scrollTop();

					if (scroll >= 72) {
						header.addClass("opaque");
					}
				});

		}

	}, 500)
});
