/* JOBS API CONSUMER */
function getUrlParameter(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

(function ($) {
  $ = jQuery;
  function setup_tabs(){
    $('#job-openings-tab-group .button').on('click', function(e){
      console.log('Tab Click', e.currentTarget.dataset.search)
      $('#job-openings-tab-group .button').removeClass('active')
      $(e.currentTarget).addClass('active');

      const list_target = $('.job-openings-list')

      if (list_target) {
        $(list_target).removeClass('SAN_FRANCISCO_CA INDIANAPOLIS_IN ALL').addClass(e.currentTarget.dataset.search)

        $('.job-openings-list').each((index, list)=>{
          const list_hight = $(list).height();

          const this_type = $(list).attr('data-type');

          if (list_hight === 0){
            $(list).addClass('empty');
            $(`#${this_type}-job-openings-inner-wrapper`).addClass('empty');
          } else {
            $(list).removeClass('empty');
            $(`#${this_type}-job-openings-inner-wrapper`).removeClass('empty');
          }
        })

          const full_list_hight = $('#job-openings-wrapper').height();
          if (full_list_hight === 0){
            $('.general-inquiry').addClass('active');
          } else {
            $('.general-inquiry').removeClass('active');
          }

      }
    })
  }

  function jobs_wrapper(team, jobs){
    const bg = window.jobPics[team];
    const id = team.toLocaleLowerCase().replace(/\ /g, '_');
    return `<div id="${id}-job-openings-inner-wrapper" class="job-openings-inner-wrapper grid-x grid-margin-x">
		<div class="small-12 medium-4 large-3 cell">
				<div class="img-overlay" role="banner" style="background-image: url('${bg}');">
        <div class="gradient"></div>
        <h3>${team}</h3></div>
		</div>

		<div class="small-12 medium-8 large-9 cell">
			<div id="${id}-job-openings-list" class="job-openings-list grid-x grid-margin-x grid-padding-x medium-up-2 large-up-3 ALL" data-type="${id}">
        ${jobs}
			</div>
      <div class="no_positions_wrapper">
        There are no positions available at this locaiton
      </div>
		</div>
	</div>`
  }

  function job_template(job){
    let location_class = job.categories.location.replace(/\ /g, '_').replace(',', '').toLocaleUpperCase();
    // let job_text = job.text.replace(/\ /g, '_').replace(',', '').toLocaleUpperCase();
    return `<div class="cell listing ${location_class}">
      <div class="inner">
        <h6>${job.categories.location} </h6>
        <h3>${job.text}</h3>
        <a href="./job?q=${job.id}&l=${job.categories.location}&t=${job.text}"><button class="button" type="button">View Role</button></a>
      </div>
    </div>`;
  }

  function generate_jobs(jobs){
    console.log(jobs)
    let jobs_templates = []
    jobs.forEach(job => {
      jobs_templates.push(job_template(job))
    });
    return jobs_templates.join('');
  }

  function get_all_jobs() {
    const list_target = document.getElementById('job-openings');
    $(".menu-careers a").attr("data-job-count", 0)
    // if (!list_target) { return false;}

    console.log("Fetching jobs...");
    $.ajax({
      type: "GET",
      url: AJAX_URL,
      data: {
        action: "wifi_get_peoplehr_data",
      },
      success: function (data) {
        console.log("Jobs:", data);
        $(".menu-careers a").attr("data-job-count", data.length)
        // if (!list_target) { return false;}
        // $('#job-openings').after(generate_jobs(data));

        let jobsByCat = {};
        data.forEach((job)=>{
          const team = job.categories.team;
          if (!jobsByCat[team]) {jobsByCat[team] = []}

          jobsByCat[team].push(job)
        });
        console.log(jobsByCat)

        const jobsHTML = Object.keys(jobsByCat).map((cat)=>{
          console.log(cat)
          return jobs_wrapper(cat,generate_jobs(jobsByCat[cat]))
        })

        $('#job-openings-wrapper').html(jobsHTML)
      },
      error: function (e) {
        console.error(e);
      },
    });
  }

  function get_job(){
    const careers = document.getElementById('careers');
    // if (!careers) { return false;}
    console.log('get_job')

    // window.scramblingTrue = true;

    // setTimeout(function(){
    //   window.scramblingTrue = false;
    // }, 1500)

    // $('#job-title').text(getUrlParameter('t'))
    // $('#job-location').text(getUrlParameter('l'))

    const location = getUrlParameter('l');
    const location_escaped = location.replace(/\ /g, '_').replace(',', '').toLocaleUpperCase();
    $('#job-title').text(getUrlParameter('t'))
    $('#job-location').text(location)
    $('.job-hero').addClass(location_escaped)

    // $("#job-location").scramble(15000, 15, "alphabet", false);
    // $("#job-title").scramble(12500, 15, "alphabet", false);

    $('.job-apply-button').css('transition', 'all 0s')
    $('.job-apply-button').css('opacity', 0)
    $('.content-target').hide();

    $.ajax({
      type: "GET",
      url: AJAX_URL,
      data: {
        action: "wifi_get_peoplehr_job_data",
        reference: getUrlParameter('q'),
      },
      success: function (data) {
        console.log("Job:", data);
        console.log("Job:", data.description);

        updateSingleJob(data);
      },
      error: function (e) {
        console.error(e);
      },
    });
  }

  function updateSingleJob(data){
    $('#job-title').text(data.text)
    $('#job-location').text(data.categories.location)
    $('.job-apply-button').attr('href', data.applyUrl).attr('target', '_blank')

    let list = ''
    list += `${data.description}`
    list += `
        ${data.lists.map((list) => {
          return '<h4>'+ list.text + '</h4>' + '<ul>'+list.content+'</ul>';
        }).join('')}`

    list += `${data.additional}`
    $('.content-target').html(list)
    $('#job-title').text(data.text)
    $('#job-location').text(data.categories.location)

    setTimeout(function(){
      $('.job-apply-button').css('transition', 'all 0.5s')
      $('.job-apply-button').css('opacity', 1)
      $('.content-target').fadeIn(1000);
    }, 1000)
  }
  if (!!window.jobSearch) {
    get_job();
  }
  get_all_jobs();
  setup_tabs();
})($);
